import React from 'react';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation'; // For navigation arrows
import 'swiper/css/autoplay';
import { EffectCoverflow, Navigation, Autoplay } from 'swiper/modules'; // Import modules

import './Gallery.css';

// Install Swiper modules
SwiperCore.use([EffectCoverflow]); // You need to import SwiperCore first

// Function to dynamically import all images from the gallery folder
const importAll = (r) => {
  return r.keys().map(r);
};

// Importing all images in the gallery folder
const images = importAll(require.context('../assets/images/gallery', false, /\.(png|jpe?g|svg)$/));

const Gallery = () => {
  return (
    <div className="gallery-container">
      <h2 className="gallery-title">Beautiful Sikkim</h2>

      {/* Swiper Carousel */}
      <Swiper
        modules={[Navigation, Autoplay]}
        centeredSlides
        slidesPerView="auto"
        effect="coverflow"
        loop
        navigation
        autoplay
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        className="swiper-wrapper"
      >
        {images.map((slide, index) => (
          <SwiperSlide
            key={index}
            style={{
              backgroundImage: `url(${slide})`,
            }}
            className="swiper-slide"
          />
        ))}
      </Swiper>
    </div>
  );
};

export default Gallery;