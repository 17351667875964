import React from 'react';
import './PresidentMessage.css';
import presidentImage from '../assets/images/president.png'; // Replace with actual image
import signatureImage from '../assets/images/sign.png'; // Replace with the signature image path

const PresidentMessage = () => {
  return (
    <div className="president-message-container">
      <h2 className="president-title">WELCOME MESSAGE</h2>
      <div className="president-message-content">
        <div className="president-image">
          <img src={presidentImage} alt="President" />
        </div>
        <div className="president-speech">
          <p>Dear Colleagues and Friends,</p>
          <p>
            It is our pleasure to welcome you all once again, for the 38th Annual Conference of the 
            North East Regional Orthopaedics Surgeons Association (NEROSA) to be held between 7th March 2025 
            to 9th March 2025 at Gangtok, Sikkim.
          </p>
          <p>
            The NEOSCON 2025 is being held with the theme of "A Move towards Movement", and we have 
            tailored a collection of seminars, speeches, and workshops to address your appetite for 
            learning from our guest speakers and learned dignitaries. Keeping the same emphasis on 
            mobility, the pre-conference workshop will focus on "Proximal Tibia Fractures".
          </p>
          <p>
            The venue for the conference will be the Lemon Tree Hotel in the heart of Gangtok town, 
            so that you can enjoy the aura of the town and visit various tourist attractions in and 
            around Gangtok. In March, Sikkim will offer a range of tourist attractions from 
            mesmerising lakes amidst snow-capped mountains to serene Buddha statues and monasteries.
          </p>
          <p>
            We look forward to your participation and are eager to host you along with your friends 
            and families.
          </p>
          {/* Add the signature before the President's name */}
          <div className="president-signature">
            <img src={signatureImage} alt="Signature" />
          </div>
          <p>
            <strong>Dr. S. K. Dewan</strong><br />
            President, Sikkim Orthopaedics Chapter.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PresidentMessage;
