import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './OrganizingCommittee.css';

const committeeData = [
  {
    role: "President",
    members: [{ name: "Dr. S. K. Dewan" }]
  },
  {
    role: "Organizing Secretary",
    members: [
      { name: "Dr. Ujjawal Pradhan", responsibility: "Event", phone: "9733089894" },
      { name: "Dr. Thejaswi S. G.", responsibility: "Scientific", phone: "9845138713" },
    ]
  },
  {
    role: "Executive Members",
    subCommittees: [
      {
        role: "Scientific",
        members: [
          { name: 'Dr. Shreel Koirala', phone: '7259725973' },
          { name: 'Dr. Tushar Sinha', phone: '8080406713' },
          
        ],
      },
      {
        role: 'Venue',
        members: [
        { name: 'Dr. Tashi Topden', phone: '8001763634' },
          { name: 'Dr. Nobin Kumar Sharma', phone: '9002855179' },
          
        ],
      },
      {
        role: 'Trade Exhibition',
        members: [
         { name: 'Dr. Tashi Topden', phone: '8001763634' },
          { name: 'Dr. Nobin Kumar Sharma', phone: '9002855179' },
         
        ],
      },
      {
        role: 'Food & Lodging',
        members: [{ name: 'Dr. Tushar Sinha', phone: '8080406713' }],
      },
      {
        role: 'Travel',
        members: [{ name: 'Dr. Tushar Sinha', phone: '8080406713' }],
      },
      {
        role: 'Souvenir',
        members: [
          { name: 'Dr. Shreel Koirala', phone: '7259725973' },
          { name: 'Dr. Tushar Sinha', phone: '8080406713' },
          
        ],
      },
      {
        role: 'Print & Media',
        members: [
          { name: 'Dr. Jerson John Mathew', phone: '8921210522' },
          { name: 'Dr. Tushar Sinha', phone: '8080406713' },
        ],
      },
      {
        role: 'Registration',
        members: [{ name: 'Dr. Shreel Koirala', phone: '7259725973' }],
      },
    ],
  },
];

const OrganizingCommittee = () => {
  return (
    <Container className="organizing-committee">
      <Row className="justify-content-center">
        <Col xs={12} className="text-center mb-4">
          <h1 className="committee-title">Organizing Committee</h1>
        </Col>
      </Row>

      <Row>
        {committeeData.map((committee, index) => (
          <Col xs={12} key={index} className="mb-3">
            <h3 className="committee-role">{committee.role}</h3>
            {committee.members && committee.members.map((member, i) => (
              <Card key={i} className="member-card mb-3">
                <Card.Body>
                  <h5 className="white-text">{member.name}</h5>
                  {member.responsibility && <p className="white-text">{member.responsibility}</p>}
                  {member.phone && <p className="white-text"><strong>Contact No. : </strong>{member.phone}</p>}
                </Card.Body>
              </Card>
            ))}
            {committee.subCommittees && committee.subCommittees.map((sub, i) => (
              <Card key={i} className="subcommittee-card mb-3">
                <Card.Body>
                  <h5 className="white-text">{sub.role}</h5>
                  <hr className="white-text"></hr>
                  {sub.members.map((member, j) => (
                    <div key={j} className="member-info">
                      <p className="white-text"><strong>{member.name}</strong></p>
                      <p className="white-text"><strong>Contact No. : </strong>{member.phone}</p>
                    </div>
                  ))}
                </Card.Body>
              </Card>
            ))}
          </Col>
        ))}
      </Row>

      <Row className="justify-content-center">
        <Col xs={12} className="text-center mt-5">
          <p className="contact-note">
            If you have any queries regarding the conference, feel free to contact the respective members listed above.
            You can also email us at <a href="mailto:neoscon2025@gmail.com">neoscon2025@gmail.com</a>.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default OrganizingCommittee;
