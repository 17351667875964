import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';
import './Navbar.css';
import nesconLogo from '../assets/images/neoscon-logo-short.png'; // Ensure the logo has a transparent background

const CustomNavbar = () => {
  const [expanded, setExpanded] = useState(false);

  const toggleNavbar = () => {
    setExpanded(!expanded);
  };

  const closeNavbar = () => {
    setExpanded(false);
  };

  return (
    <Navbar 
      expanded={expanded} 
      expand="md" 
      bg="dark" 
      variant="dark" 
      sticky="top" 
      className="custom-navbar"
    >
      <Container>
        <Navbar.Brand as={Link} to="/" onClick={closeNavbar}>
          <img src={nesconLogo} alt="Nescon 2024" className="navbar-logo" />
        </Navbar.Brand>
        <Navbar.Toggle 
          aria-controls="basic-navbar-nav" 
          onClick={toggleNavbar} 
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link as={Link} to="/" onClick={closeNavbar}>Home</Nav.Link>
            <Nav.Link as={Link} to="/gallery" onClick={closeNavbar}>Gallery</Nav.Link>
            <Nav.Link as={Link} to="/registration" onClick={closeNavbar}>Registration</Nav.Link>
            <Nav.Link as={Link} to="/abstract-submission" onClick={closeNavbar}>Abstract Submission</Nav.Link>
            
            <Nav.Link as={Link} to="/speakers" onClick={closeNavbar}>Speakers</Nav.Link>
            <Nav.Link as={Link} to="/venue" onClick={closeNavbar}>Venue</Nav.Link>
            {/* <Nav.Link as={Link} to="#schedule" onClick={closeNavbar}>Schedule</Nav.Link> */}
            
            <Nav.Link as={Link} to="/contact-us" onClick={closeNavbar}>Contact Us</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default CustomNavbar;
