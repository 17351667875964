// src/components/RegistrationSuccess.js
import React from 'react';
import { Link } from 'react-router-dom';

const RegistrationSuccess = () => {
    return (
        <div style={styles.container}>
            <div style={styles.card}>
                <h1 style={styles.heading}>Registration Successful</h1>
                <p style={styles.message}>You'll receive your details on your email.</p>
                <Link to="/abstract-submission" style={styles.link}>
                    For Abstract Submission, click here
                </Link>
            </div>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#f6f6e9',
        padding: '20px', // Added padding for better spacing on smaller screens
    },
    card: {
        backgroundColor: '#ffffff', // White background for the card
        padding: '30px 20px',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
        maxWidth: '400px', // Restrict max width for better readability on larger screens
        width: '100%', // Ensure the card takes full width on mobile
    },
    heading: {
        color: '#0d243d',
        fontSize: '2rem',
        marginBottom: '20px',
    },
    message: {
        color: '#00afef',
        fontSize: '1.2rem',
        marginBottom: '20px',
    },
    link: {
        color: '#ffffff',
        background: 'linear-gradient(135deg, #0d243d, #00afef)',
        padding: '10px 20px',
        borderRadius: '5px',
        textDecoration: 'none',
        fontSize: '1rem',
        transition: 'background-color 0.3s',
        display: 'inline-block',
        width: '100%', // Make the button take full width on mobile
        maxWidth: '400px', // Restrict the button's max width on larger screens
        margin: '0 auto', // Center the button
    },
};

export default RegistrationSuccess;
