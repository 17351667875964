import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import logo from '../assets/images/neoscon-logo.png';

function Footer() {
  return (
    <footer className="footer mt-auto py-4">
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-md-3 col-12 text-center text-md-left mb-3 mb-md-0">
            <img src={logo} alt="Site Logo" className="footer-logo" />
          </div>
          <div className="col-md-5 col-12 text-center">
            <ul className="list-inline mb-3 mb-md-0">
              <li className="list-inline-item">
                <Link to="/">Home</Link>
              </li>
              <li className="list-inline-item">
                <Link to="/gallery">Gallery</Link>
              </li>
              <li className="list-inline-item">
                <Link to="/abstract-submission">Abstract Submission</Link>
              </li>
              <li className="list-inline-item">
                <Link to="/speakers">Speakers</Link>
              </li>
              <li className="list-inline-item">
                <Link to="/contact-us">Contact Us</Link>
              </li>
              
            </ul>
          </div>
          <div className="col-md-3 col-12 text-center text-md-right">
            <Link to="/registration" className="btn btn-primary register-btn">
              Register Now
            </Link>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 text-center">
            <p className="copyright-text">© 2024 NEOSCON. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
